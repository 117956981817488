'use client';

import { Alert, AlertTitle, Box, Button } from '@mui/material';

export default function Error({ error, reset }: { error: Error; reset: () => void }): JSX.Element {
  return (
    <Alert severity="error" sx={{ width: '100%' }}>
      <AlertTitle>Something went wrong!</AlertTitle>
      <Box sx={{ mb: 4 }}>{error.message}</Box>
      <Button
        disableElevation
        onClick={() => {
          reset();
        }}
        variant="contained"
      >
        Retry
      </Button>
    </Alert>
  );
}
